/* 
@font-face {
  font-family: "Geist";
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: local("Geist"), url("/src/assets/Geist-Regular.woff2") format("woff2");
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Geist Variable';
}
