@tailwind base;
@tailwind components;
@tailwind utilities;

.filter-indigo {
  filter: invert(31%) sepia(37%) saturate(2197%) hue-rotate(208deg) brightness(87%) contrast(90%);
}

.filter-indigo {
  filter: invert(31%) sepia(37%) saturate(2197%) hue-rotate(208deg) brightness(87%) contrast(90%);
}

.filter-indigo-light {
  filter: invert(15%) sepia(51%) saturate(5335%) hue-rotate(227deg) brightness(95%) contrast(91%);
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
}


.payment-paypal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.unavailable {
  left: 0;
  bottom: 0;
  background-color: red;
  color: white;
}

.start {
  /* round the borders on the left of unavailable */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.end {
  /* round the borders on the right of unavailable */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.gradient-text {
  background-image: linear-gradient(to right, #1e3a8a 0%, #0ea5e9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.custom-text-size {
  font-size: calc(2rem + 2.76vw); /* Adjust this value based on your needs */
}

/* fadeInEffect.css */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}


.auto-resize-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.auto-resize-text {
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: none;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container .logo-image {
  object-fit: contain;
}

.location-item:hover .bg-indigo-200 {
  background-color: #4f46e5;
  transition: background-color 0.2s ease-in-out;
}


.background {
  background-color: #FAD6A0;
}

.bg-pattern {
  background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3h1v1H1V3zm2-2h1v1H3V1zM0 0h1v1H0V0zm2 0h1v1H2V0zm0 2h1v1H2V2zm-1-1h1v1H1V1zm2 0h1v1H3V1zm0 2h1v1H3V3zM1 0h1v1H1V0zm0 2h1v1H1V2z' fill='%23000' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
}